import React from "react";
import "./Launching.scss";
import LaunchingResident from "../../HomeSection/launching/launch_adora.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faCircleCheck,
  faHouse,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Launchingruko from "../../../media/Armont_Hardsell.webp";
const rumah = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6287860478606&text=Halo%20Gerry,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20(New%20Launch%20Adora)%20https://marketing-bsdcity.id/%20&type=phone_number&app_absent=0";
  window.location.href = whatsappLink;
};

const ruko = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6287860478606&text=Halo%20Gerry,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20(New%20Launch%20Wynyard)%20https://marketing-bsdcity.id/%20&type=phone_number&app_absent=0";
  window.location.href = whatsappLink;
};

const Launching = () => {
  return (
    <div className="launching-containers">
      <div className="divider" />
      <div id="newlaunchingwynyard" className="launching">
        <div className="launching-img">
          <img className="img" alt="test" src={Launchingruko} />
        </div>
        <div className="launching-content">
          <div className="launching-start">New Luxury Cluster</div>
          <div className="launching-title">The Armont Residence</div>
          <div className="launching-benefit">
            <hr />
            <div className="benefit-title">Benefits</div>
            <div className="benefit">
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Subsidi DP
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Discount Ratusan Juta
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free AC
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Lift*
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free CCTV
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Canopy
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Wet Kitchen
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Smartdoor Lock
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Smarthome System
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;EV Charging Standart
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Sanitary Kohler
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Full Marmer
            </div>
          </div>
          <button onClick={ruko} className="launching-button">
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
      <div id="newlaunchingadora" className="launching">
        <div className="launching-img">
          <img className="img" alt="test" src={LaunchingResident} />
        </div>
        <div className="launching-content">
          <div className="launching-start">New Launching</div>
          <div className="launching-title">Adora Luxe</div>
          <div className="launching-title">Belova Classic</div>
          <div className="launching-title">Primes</div>
          <div className="launching-benefit">
            <hr />
            <div className="benefit-title">Promo New Launching</div>
            <div className="benefit">
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Tanpa DP
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Subsidi Biaya KPR
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Canopy
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free AC
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Air Purifier
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Smartdoor Lock
              <br />
              <FontAwesomeIcon color="#ffce6c" icon={faCircleCheck} />
              &nbsp;Free Solar Panel
            </div>
            <hr />
          </div>
          <div className="launching-icon">
            <FontAwesomeIcon color="#FFCE6C" icon={faArrowsUpDownLeftRight} />
            <span>&nbsp; : 112m² &nbsp;</span>
            <FontAwesomeIcon color="#FFCE6C" icon={faHouse} />
            <span>&nbsp; : 190m² &nbsp;</span>
            <FontAwesomeIcon color="#FFCE6C" icon={faBed} />
            <span>&nbsp; : 5+1 &nbsp;</span>
            <FontAwesomeIcon color="#FFCE6C" icon={faShower} />
            <span>&nbsp; : 3+1 &nbsp;</span>
          </div>
          <div className="launching-circle">
            <div className="circle-text">
              9
              <br />
              Juta/Bulan
            </div>
          </div>
          <button onClick={rumah} className="launching-button">
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
      <div className="divider1" />
    </div>
  );
};

export default Launching;
