import React from "react";
import "./Promo.scss";
import Promoimage from "../../../media/promo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const Promo = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287860478606&text=Halo%20Gerry,%20Saya%20bisa%20minta%20detail%20Promo%20terbaru%20produk%20BSD%20City%20https://marketing-bsdcity.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div>
      <div className="containerPenawaran">
        <div className="containergambar">
          <img className="penawarangambar" alt="oke" src={Promoimage} />
        </div>
        <div id="promorumah" className="containercontent">
          <div className="penawarantitle2">Promo</div>
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Tanpa DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free PPN</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free Biaya KPR</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free Biaya BPHTB</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>
                &nbsp;&nbsp;Free AJB, BBN & SHM
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free Kitchen Set</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>
                &nbsp;&nbsp;Free Smarthome System
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>
                &nbsp;&nbsp;Free Smartdoor Lock
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free Furnished</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Lift Include</span>
            </div>
          </div>
          <div className="disclaimer">*S&K Berlaku</div>
          <div className="divbutton">
            {" "}
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Dapatkan Promo Terbaik Anda
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
