import Armont1 from "./Armount (1).webp";
import Armont2 from "./Armount (2).webp";
import Armont3 from "./Armount (3).webp";
import Armont4 from "./Armount (4).webp";
import Armont5 from "./Armount (5).webp";
import Armont6 from "./Armount (6).webp";
import Armont7 from "./Armount (7).webp";
import Armont8 from "./Armount (8).webp";
import Armont9 from "./Armount (9).webp";
import Armont10 from "./Armount (10).webp";

const Armont = [
  Armont1,
  Armont2,
  Armont3,
  Armont4,
  Armont5,
  Armont6,
  Armont7,
  Armont8,
  Armont9,
  Armont10,
];

export default Armont;
