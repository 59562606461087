import React from "react";
import "./Cardhome.scss";
import Card from "../../../component/Card/Card";
import Myza from "../../../media/Rumah/Myza";
import Fleek from "../../../media/Rumah/Fleekhauz";
import data from "./data.json";
import RumahLogo from "../../../media/RumahLogo/index";
import Teraria from "../../../media/Rumah/Terarria";
import Tanakayu from "../../../media/Rumah/Tanakayu";
import Hera from "../../../media/Rumah/Hera";
import Askara from "../../../media/Rumah/Askara";
import Eonna from "../../../media/Rumah/Eonna";
import Blizfield from "../../../media/Rumah/Blizfield";
import Caelus from "../../../media/Rumah/Caelus";
import Zora from "../../../media/Rumah/Zora";
import Enchante from "../../../media/Rumah/Enchante";
import Layton from "../../../media/Rumah/Layton";
import Armont from "../../../media/Rumah/Armont/Armont";
import {faBed} from "@fortawesome/free-solid-svg-icons";

const imageMap = {
  Myza,
  Fleek,
  Teraria,
  Tanakayu,
  Hera,
  Askara,
  Eonna,
  Blizfield,
  Caelus,
  Zora,
  Enchante,
  Layton,
  Armont,

  // Add other image arrays here if you have more sets
};

const Cardhome = () => {
  return (
    <div id="produk" className="cardhome-container">
      {data.cards.map((card, index) => {
        // Determine the logo dynamically
        const myzaLogo = RumahLogo[card.logo];
        return (
          <Card
            key={index}
            carousel={imageMap[card.imagesKey]}
            logo={myzaLogo} // Dynamically import logo images
            title={card.title}
            price={card.price}
            subprice={card.subprice}
            details={card.details}
            whatsappLink={card.whatsappLink}
            iconcard={faBed} // Pass the WhatsApp link
          />
        );
      })}
    </div>
  );
};

export default Cardhome;
